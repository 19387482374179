@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

body {
  background-image: url("./assets/bg-violet.jpg");
  background-repeat: no-repeat;
  background-size: 1600px;
}

#menu {
  background-image: url("./assets/bg-violet.jpg");
  background-position: 90% 15%;
  background-size: 600px;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  body {
    background-size: 1600px;
    background-position: 50% -25px;
  }
}
